<template>
  <CCard :class="{ 'agent-panel': true, 'border-0': noBorder, 'two-agents' : agent.team}">
    <CCardBody :class="{'hv-header-card p-0': true, 'bg-transparent':noBorder}">
        <div class="text-dark hv-agent-details">
            <div class="main-cont">
                <div class="agent-one">

                    <div class="hv-agent-photo">
                        <div v-if="agent.use_agentphoto1" class="position-relative d-flex">
                            <img :src="agent.agentphoto1_url" alt="Agent Photo 1" class="agent-photo " />
                        </div>
                        <div v-else>
                            <div v-if="agent.use_agentphoto1 && agent.agentphoto1_url">
                                <img :src="agent.agentphoto1_url" alt="Agent Photo" class="agent-photo-one" />
                            </div>
                        </div>
                    </div>
                    <div class="hv-agent-info">
                        <div class="left">
                            <div v-if="agent.firstname" style="margin-bottom: -6px; margin-top: 6px;">
                                <span class="agent-name"> {{ agent.firstname }} {{ agent.lastname }} </span></br>
                            </div>
                            <p class="agent-company" style="margin-top: 10px;">
                                {{ agent.company || 'AGENCY NAME' }}
                            </p>
                            <ul class="list-unstyled mb-2">
                                <li v-if="agent.phone" class="d-flex  align-items-center"
                                    style="margin-bottom: 10px; margin-top: -7px;">
                                    {{ agent.phone }}
                                </li>
                                <li v-if="agent.email" style="margin-bottom: 10px;">
                                    <a v-bind:href="'mailto:' + agent.email"> {{ agent.email }}</a>
                                </li>
                                <li v-if="agent.idx_dre"><span class="text-muted">Licence:</span>&nbsp; {{ agent.idx_dre
                                    }}</li>
                            </ul>
                            <p class="agent-licence" style="margin-bottom: 18px;">
                                Licence: {{ agent.agent_id || '1234567' }}
                            </p>
                            <span class="d-print-none"
                                v-if="agent.primary_domain || agent.facebook || agent.twitter || agent.linkedin">
                            </span>
                        </div>
                    </div>
                    <div class="d-print-none hv-header-social d-flex">
                      <CButton type="link" v-if="agent.primary_domain" size=""
                          v-bind:href="`http://${agent.primary_domain}`" target="_blank">
                          <svg width="31" height="31" viewBox="0 0 31 31" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M27.6786 0H3.32143C2.44053 0 1.59571 0.349935 0.972824 0.972824C0.349935 1.59571 0 2.44053 0 3.32143L0 27.6786C0 28.5595 0.349935 29.4043 0.972824 30.0272C1.59571 30.6501 2.44053 31 3.32143 31H12.8186V20.4607H8.45926V15.5H12.8186V11.7191C12.8186 7.41855 15.3789 5.04304 19.3003 5.04304C21.1783 5.04304 23.1421 5.37795 23.1421 5.37795V9.59893H20.9783C18.8463 9.59893 18.1814 10.922 18.1814 12.2789V15.5H22.9407L22.1795 20.4607H18.1814V31H27.6786C28.5595 31 29.4043 30.6501 30.0272 30.0272C30.6501 29.4043 31 28.5595 31 27.6786V3.32143C31 2.44053 30.6501 1.59571 30.0272 0.972824C29.4043 0.349935 28.5595 0 27.6786 0Z"
                                  fill="#395185" />
                          </svg>
                      </CButton>
                      <CButton type="link" size="" v-if="agent.facebook"
                          v-bind:href="`http://www.facebook.com/${agent.facebook}`" target="_blank">
                          <svg width="31" height="31" viewBox="0 0 31 31" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M27.6786 0H3.32143C2.44053 0 1.59571 0.349935 0.972824 0.972824C0.349935 1.59571 0 2.44053 0 3.32143L0 27.6786C0 28.5595 0.349935 29.4043 0.972824 30.0272C1.59571 30.6501 2.44053 31 3.32143 31H12.8186V20.4607H8.45926V15.5H12.8186V11.7191C12.8186 7.41855 15.3789 5.04304 19.3003 5.04304C21.1783 5.04304 23.1421 5.37795 23.1421 5.37795V9.59893H20.9783C18.8463 9.59893 18.1814 10.922 18.1814 12.2789V15.5H22.9407L22.1795 20.4607H18.1814V31H27.6786C28.5595 31 29.4043 30.6501 30.0272 30.0272C30.6501 29.4043 31 28.5595 31 27.6786V3.32143C31 2.44053 30.6501 1.59571 30.0272 0.972824C29.4043 0.349935 28.5595 0 27.6786 0Z"
                                  fill="#395185" />
                          </svg>
                      </CButton>
                      <CButton type="link" size="" v-if="agent.twitter"
                          v-bind:href="`http://www.twitter.com/${ agent.twitter }`" target="_blank">
                          <svg width="31" height="31" viewBox="0 0 31 31" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M27.6786 0H3.32143C1.48772 0 0 1.48772 0 3.32143V27.6786C0 29.5123 1.48772 31 3.32143 31H27.6786C29.5123 31 31 29.5123 31 27.6786V3.32143C31 1.48772 29.5123 0 27.6786 0ZM24.2949 10.9884C24.3087 11.1821 24.3087 11.3828 24.3087 11.5766C24.3087 17.5759 19.7417 24.4886 11.3967 24.4886C8.82254 24.4886 6.43527 23.7413 4.42857 22.4542C4.79531 22.4958 5.14821 22.5096 5.52187 22.5096C7.64621 22.5096 9.59754 21.79 11.1545 20.5721C9.16161 20.5306 7.48705 19.2228 6.91272 17.4237C7.61161 17.5275 8.24129 17.5275 8.96094 17.3406C6.88504 16.9185 5.32812 15.0917 5.32812 12.8844V12.829C5.93013 13.1681 6.63594 13.3757 7.37634 13.4033C6.75387 12.9893 6.24358 12.4276 5.89102 11.7683C5.53845 11.1091 5.35458 10.3728 5.3558 9.62522C5.3558 8.78103 5.57723 8.00603 5.97165 7.33482C8.2067 10.0888 11.5627 11.8879 15.327 12.0817C14.6835 9.00246 16.9877 6.50446 19.7556 6.50446C21.0634 6.50446 22.2397 7.05112 23.0701 7.93683C24.0942 7.74308 25.0768 7.3625 25.9487 6.84353C25.6096 7.89531 24.8969 8.78103 23.9558 9.34152C24.8692 9.24464 25.7549 8.98862 26.5714 8.63571C25.9556 9.54219 25.1806 10.3449 24.2949 10.9884Z"
                                  fill="#55ACEE" />
                          </svg>
                      </CButton>
                      <CButton type="link" size="" v-if="agent.linkedin"
                          v-bind:href="`http://www.linkedin.com/in/${ agent.linkedin }`" target="_blank">
                          <svg width="31" height="31" viewBox="0 0 31 31" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M3.83357e-07 2.58991C3.83357e-07 1.90303 0.272865 1.24427 0.758567 0.75857C1.24427 0.272868 1.90302 3.45271e-06 2.58991 3.45271e-06H28.4073C28.7477 -0.000552506 29.0848 0.0660375 29.3995 0.195961C29.7141 0.325885 30 0.51659 30.2408 0.757159C30.4817 0.997728 30.6727 1.28344 30.803 1.59793C30.9332 1.91242 31.0002 2.24951 31 2.58991V28.4073C31.0004 28.7477 30.9336 29.0849 30.8035 29.3996C30.6734 29.7142 30.4826 30.0001 30.2419 30.2409C30.0012 30.4817 29.7154 30.6727 29.4009 30.803C29.0863 30.9332 28.7491 31.0002 28.4087 31H2.58991C2.24968 31 1.91278 30.933 1.59847 30.8027C1.28415 30.6725 0.998582 30.4816 0.758069 30.2409C0.517555 30.0003 0.326813 29.7146 0.196741 29.4002C0.0666687 29.0858 -0.000184726 28.7489 3.83357e-07 28.4087V2.58991ZM12.2704 11.8195H16.468V13.9275C17.074 12.7156 18.624 11.625 20.9532 11.625C25.4186 11.625 26.4768 14.0388 26.4768 18.4675V26.6713H21.9579V19.4765C21.9579 16.9542 21.352 15.531 19.8132 15.531C17.6785 15.531 16.7907 17.0655 16.7907 19.4765V26.6713H12.2704V11.8195ZM4.52036 26.4782H9.04073V11.625H4.52036V26.4768V26.4782ZM9.6875 6.78055C9.69602 7.16759 9.62715 7.55243 9.48493 7.91249C9.3427 8.27255 9.12999 8.60058 8.85928 8.87732C8.58857 9.15406 8.2653 9.37394 7.90846 9.52406C7.55162 9.67417 7.16838 9.7515 6.78125 9.7515C6.39412 9.7515 6.01088 9.67417 5.65404 9.52406C5.2972 9.37394 4.97393 9.15406 4.70322 8.87732C4.43251 8.60058 4.21979 8.27255 4.07757 7.91249C3.93535 7.55243 3.86648 7.16759 3.875 6.78055C3.89173 6.02084 4.20527 5.29789 4.74847 4.76652C5.29168 4.23514 6.02136 3.93759 6.78125 3.93759C7.54114 3.93759 8.27082 4.23514 8.81402 4.76652C9.35723 5.29789 9.67077 6.02084 9.6875 6.78055Z"
                                  fill="#0A66C2" />
                          </svg>
                      </CButton>
                    </div>
                </div>
                <div v-if="agent.team" class="agent-two">
                    <div class="hv-agent-photo">
                        <div v-if="agent.use_agentphoto2 && agent.agentphoto2_url"
                            class="position-relative d-flex">
                            <img v-if="agent.team && agent.use_agentphoto2 && agent.agentphoto2_url"
                                :src="agent.agentphoto2_url" alt="Agent Photo 2" class="agent-photo " />
                        </div>
                    </div>
                    <div class="hv-agent-info d-flex">
                        <div class="left">
                            <div v-if="agent.firstname2" style="margin-bottom: 15px;">
                                <span class="agent-name d-block mt-3" v-if="agent.team">{{ agent.firstname2 }} {{
                                    agent.lastname2 }}</span>
                            </div>
                            <p class="agent-company" style="margin-top: 10px;">
                                {{ agent.company || 'AGENCY NAME' }}
                            </p>
                            <ul class="list-unstyled mb-2">
                                <li v-if="agent.team && agent.phone2" class="d-flex align-items-center" style="margin-bottom: 10px; margin-top: -7px;">
                                    <svg class="mr-1" width="12" height="12" viewBox="0 0 12 12" fill="none"
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <rect width="12" height="12" fill="url(#pattern0)" />
                                        <defs>
                                            <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1"
                                                height="1">
                                                <use xlink:href="#image0_22:2" transform="scale(0.0833333)" />
                                            </pattern>
                                            <image id="image0_22:2" width="12" height="12"
                                                xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAsSAAALEgHS3X78AAAAy0lEQVQoz4XRrUpEYRDG8d85IojbDWLYpE2QY9k7ELvVYBi8BO/AaJUB8QbUoCzsHVg8F6BgEMRkdZsf5V14MewZGJiHmT/z1UTEGHfYwW5mvlpiLS6wh3WcG7AWh5U+iojJEPBd6Re8DQGzEn9hkpkfQ8B1iUdYOs4CmOKp6MuI2FgKZOYPjjHHFqY1FBGb9SGaKnGAe6ziHad4LL6NG5w1dbsC3ZafLA4xqkrmbQ1k5gz71U51MTyv/F+q7/vPruuu0GMNY/ziASd/B5UzcIQwXIIAAAAASUVORK5CYII=" />
                                        </defs>
                                    </svg>
                                    &nbsp; {{ agent.phone2 }}
                                </li>
                                <li v-if="agent.team && agent.email2" style="margin-bottom: 10px;">
                                    &nbsp; <a v-bind:href="'mailto:' + agent.email2"> {{ agent.email2 }}</a>
                                </li>

                                <li v-if="agent.team && agent.idx_dre2"><span class="text-muted">Licence:&nbsp;</span>
                                    {{ agent.idx_dre2 }}</li>
                            </ul>
                            <p class="agent-licence" style="margin-bottom: 18px;">
                                Licence: {{ agent.agent_id || '1234567' }}
                            </p>
                            <span class="d-print-none"
                                v-if="agent.primary_domain || agent.facebook || agent.twitter || agent.linkedin">
                            </span>
                        </div>
                    </div>
                    <div class="d-print-none hv-header-social d-flex">
                      <CButton type="link" v-if="agent.primary_domain" size=""
                          v-bind:href="`http://${agent.primary_domain}`" target="_blank">
                          <svg width="31" height="31" viewBox="0 0 31 31" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M27.6786 0H3.32143C2.44053 0 1.59571 0.349935 0.972824 0.972824C0.349935 1.59571 0 2.44053 0 3.32143L0 27.6786C0 28.5595 0.349935 29.4043 0.972824 30.0272C1.59571 30.6501 2.44053 31 3.32143 31H12.8186V20.4607H8.45926V15.5H12.8186V11.7191C12.8186 7.41855 15.3789 5.04304 19.3003 5.04304C21.1783 5.04304 23.1421 5.37795 23.1421 5.37795V9.59893H20.9783C18.8463 9.59893 18.1814 10.922 18.1814 12.2789V15.5H22.9407L22.1795 20.4607H18.1814V31H27.6786C28.5595 31 29.4043 30.6501 30.0272 30.0272C30.6501 29.4043 31 28.5595 31 27.6786V3.32143C31 2.44053 30.6501 1.59571 30.0272 0.972824C29.4043 0.349935 28.5595 0 27.6786 0Z"
                                  fill="#395185" />
                          </svg>
                      </CButton>
                      <CButton type="link" size="" v-if="agent.facebook"
                          v-bind:href="`http://www.facebook.com/${agent.facebook}`" target="_blank">
                          <svg width="31" height="31" viewBox="0 0 31 31" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M27.6786 0H3.32143C2.44053 0 1.59571 0.349935 0.972824 0.972824C0.349935 1.59571 0 2.44053 0 3.32143L0 27.6786C0 28.5595 0.349935 29.4043 0.972824 30.0272C1.59571 30.6501 2.44053 31 3.32143 31H12.8186V20.4607H8.45926V15.5H12.8186V11.7191C12.8186 7.41855 15.3789 5.04304 19.3003 5.04304C21.1783 5.04304 23.1421 5.37795 23.1421 5.37795V9.59893H20.9783C18.8463 9.59893 18.1814 10.922 18.1814 12.2789V15.5H22.9407L22.1795 20.4607H18.1814V31H27.6786C28.5595 31 29.4043 30.6501 30.0272 30.0272C30.6501 29.4043 31 28.5595 31 27.6786V3.32143C31 2.44053 30.6501 1.59571 30.0272 0.972824C29.4043 0.349935 28.5595 0 27.6786 0Z"
                                  fill="#395185" />
                          </svg>
                      </CButton>
                      <CButton type="link" size="" v-if="agent.twitter"
                          v-bind:href="`http://www.twitter.com/${ agent.twitter}`" target="_blank">
                          <svg width="31" height="31" viewBox="0 0 31 31" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M27.6786 0H3.32143C1.48772 0 0 1.48772 0 3.32143V27.6786C0 29.5123 1.48772 31 3.32143 31H27.6786C29.5123 31 31 29.5123 31 27.6786V3.32143C31 1.48772 29.5123 0 27.6786 0ZM24.2949 10.9884C24.3087 11.1821 24.3087 11.3828 24.3087 11.5766C24.3087 17.5759 19.7417 24.4886 11.3967 24.4886C8.82254 24.4886 6.43527 23.7413 4.42857 22.4542C4.79531 22.4958 5.14821 22.5096 5.52187 22.5096C7.64621 22.5096 9.59754 21.79 11.1545 20.5721C9.16161 20.5306 7.48705 19.2228 6.91272 17.4237C7.61161 17.5275 8.24129 17.5275 8.96094 17.3406C6.88504 16.9185 5.32812 15.0917 5.32812 12.8844V12.829C5.93013 13.1681 6.63594 13.3757 7.37634 13.4033C6.75387 12.9893 6.24358 12.4276 5.89102 11.7683C5.53845 11.1091 5.35458 10.3728 5.3558 9.62522C5.3558 8.78103 5.57723 8.00603 5.97165 7.33482C8.2067 10.0888 11.5627 11.8879 15.327 12.0817C14.6835 9.00246 16.9877 6.50446 19.7556 6.50446C21.0634 6.50446 22.2397 7.05112 23.0701 7.93683C24.0942 7.74308 25.0768 7.3625 25.9487 6.84353C25.6096 7.89531 24.8969 8.78103 23.9558 9.34152C24.8692 9.24464 25.7549 8.98862 26.5714 8.63571C25.9556 9.54219 25.1806 10.3449 24.2949 10.9884Z"
                                  fill="#55ACEE" />
                          </svg>
                      </CButton>
                      <CButton type="link" size="" v-if="agent.linkedin"
                          v-bind:href="`http://www.linkedin.com/in/${ agent.linkedin }`" target="_blank">
                          <svg width="31" height="31" viewBox="0 0 31 31" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M3.83357e-07 2.58991C3.83357e-07 1.90303 0.272865 1.24427 0.758567 0.75857C1.24427 0.272868 1.90302 3.45271e-06 2.58991 3.45271e-06H28.4073C28.7477 -0.000552506 29.0848 0.0660375 29.3995 0.195961C29.7141 0.325885 30 0.51659 30.2408 0.757159C30.4817 0.997728 30.6727 1.28344 30.803 1.59793C30.9332 1.91242 31.0002 2.24951 31 2.58991V28.4073C31.0004 28.7477 30.9336 29.0849 30.8035 29.3996C30.6734 29.7142 30.4826 30.0001 30.2419 30.2409C30.0012 30.4817 29.7154 30.6727 29.4009 30.803C29.0863 30.9332 28.7491 31.0002 28.4087 31H2.58991C2.24968 31 1.91278 30.933 1.59847 30.8027C1.28415 30.6725 0.998582 30.4816 0.758069 30.2409C0.517555 30.0003 0.326813 29.7146 0.196741 29.4002C0.0666687 29.0858 -0.000184726 28.7489 3.83357e-07 28.4087V2.58991ZM12.2704 11.8195H16.468V13.9275C17.074 12.7156 18.624 11.625 20.9532 11.625C25.4186 11.625 26.4768 14.0388 26.4768 18.4675V26.6713H21.9579V19.4765C21.9579 16.9542 21.352 15.531 19.8132 15.531C17.6785 15.531 16.7907 17.0655 16.7907 19.4765V26.6713H12.2704V11.8195ZM4.52036 26.4782H9.04073V11.625H4.52036V26.4768V26.4782ZM9.6875 6.78055C9.69602 7.16759 9.62715 7.55243 9.48493 7.91249C9.3427 8.27255 9.12999 8.60058 8.85928 8.87732C8.58857 9.15406 8.2653 9.37394 7.90846 9.52406C7.55162 9.67417 7.16838 9.7515 6.78125 9.7515C6.39412 9.7515 6.01088 9.67417 5.65404 9.52406C5.2972 9.37394 4.97393 9.15406 4.70322 8.87732C4.43251 8.60058 4.21979 8.27255 4.07757 7.91249C3.93535 7.55243 3.86648 7.16759 3.875 6.78055C3.89173 6.02084 4.20527 5.29789 4.74847 4.76652C5.29168 4.23514 6.02136 3.93759 6.78125 3.93759C7.54114 3.93759 8.27082 4.23514 8.81402 4.76652C9.35723 5.29789 9.67077 6.02084 9.6875 6.78055Z"
                                  fill="#0A66C2" />
                          </svg>
                      </CButton>
                    </div>
                </div>

            </div>

            <!-- <CButton v-if="agent.email" v-bind:href="`mailto:${ agent.email}`"
                    v-bind:class="`btn-${agent.specificColor || 'primary outline'}`" variant="outline"
                    class="d-print-none agent-details-btn" block>Contact Me
                </CButton> -->
                <!-- <div class="hv-agent-logo" v-if="agent.logo">
                    <p>
                    <img className="agent-logo"
                          :src="agent.logo"
                          v-bind:alt="agent.company + ' logo'"
                          height="50"
                    />

                    </p>
                </div> -->
        </div>
    </CCardBody>
</CCard>
</template>

<script>
import { cibFacebookF, cibTwitter, cibLinkedinIn } from "@coreui/icons";

export default {
  name: "HeaderAgentForm",
  components: {},
  facebookIcon: cibFacebookF,
  twitterIcon: cibTwitter,
  cibLinkedinInIcon: cibLinkedinIn,
  props: {
    agent: Object,
    contact: Object,
    noBorder: { type: Boolean, default: false },
  },

  created() {},
};
</script>

<style scoped lang="scss">
.agent-panel {
  // background: #F7F7F7;
  width: 321px;
  //margin-left: auto;
  //padding: 40px;

  &.two-agents {
    width: 462px;
  }

  .agent-photo {
    max-height: 100px;
    max-width: 100px;
    height: 100px;
    object-fit: cover;
  }

  .agent-photo-one {
    max-height: 100px;
    max-width: 100px;
    height: 100px;
    width: 100px;
    object-fit: cover;
  }

  .agent-logo {
    width: 100px;
    height: 70px;
  }

  .agent-licence {
    font-size: 16px;
  }

  ul {
    font-size: 16px;
  }
}

.main-cont {
  display: flex;
  justify-content: space-between;
  background: #F7F7F7;
  //background: #F7F7F7;

  .hv-header-social {
    padding-bottom: 17px;
    align-self: flex-end;

    .btn {
      padding-left: 0;
    }
  }

  .agent-one, .agent-two {
    word-break: break-all;
  }
}

.hv-header-card {
  //@at-rootpadding: 20px 15px;
  //box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  background: #fff;
  background-color: hsla(0, 0%, 100%, 0.85);

  .hv-agent-details {
    .hv-agent-photo,
    .hv-agent-info {
      // flex: 0 0 50%;
      // max-width: 50%;
      // padding-right: 15px;
      // padding-left: 15px;
    }

    .hv-agent-logo {
      position: absolute;
      top: 40px;
      right: 40px;
    }

    .hv-agent-info {
      .agent-name {
        font-size: 16px;
        line-height: 0;
      }

      .agent-company {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 16px;
      }

      .hv-header-social {
        .btn {
          margin-bottom: 10px;
          padding: 0;
        }
      }

      .btn {
        padding: 0.5rem 0;
      }
    }
  }
}

</style>
