import BaseApi from "./base-api";

class DbcApi extends  BaseApi {
    constructor() {
        super();
    }

    async getContactDetails(contactId) {

        const { data } = await this.API.get("homevalapi", `/api/contacts/${contactId}/details`);

        return data.data;
    }

    async createContact(contact) {
        const { data: { data: { contact: updatedContact }}} = await this.API.put("homevaldmgapi", "/api/contacts/widgetLead", { body: contact });

        return updatedContact;
    }
}

export default new DbcApi();
